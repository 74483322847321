<template>
  <div class="page-section ms-container">
    <h1
      v-if="sectionTitle"
      class="text-ms-secondary page-section-title"
      :style="titleStyle"
    >
      {{ sectionTitle }}
    </h1>
    <div class="page-section-body">
      <slot name="body" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    sectionTitle: {
      type: String,
      default: '',
    },
    titleMaxWidth: {
      type: Number,
      default: 100,
    },
  },
  setup() {},
  computed: {
    titleStyle() {
      return `max-width: ${this.titleMaxWidth}%`;
    },
  },
});
</script>

<style scoped lang="scss">
.page-section {
  @include responsive-styles(padding-top, 40px, 40px, 50px, 60px);
  @include responsive-styles(padding-bottom, 40px, 40px, 50px, 60px);
  .page-section-title {
    margin: 0 !important;
    min-width: 355px;
  }
  .page-section-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
  }
}
</style>
