<template>
  <div class="preview-holder">
    <div class="preview-icon bg-ms-beige">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M16.6667 7.0625H14.6266L11.0998 3.93848C11.2099 3.74967 11.2777 3.5331 11.2777 3.29915C11.2777 2.59473 10.7044 2.02148 10 2.02148C9.29557 2.02148 8.72233 2.59473 8.72233 3.29915C8.72233 3.53312 8.7901 3.74972 8.90027 3.93852L5.37378 7.0625H3.33333C2.59798 7.0625 2 7.66048 2 8.39583V16.6458C2 17.3812 2.59798 17.9792 3.33333 17.9792H16.6667C17.402 17.9792 18 17.3812 18 16.6458V8.39583C18 7.66048 17.402 7.0625 16.6667 7.0625ZM10 2.68815C10.3369 2.68815 10.611 2.96224 10.611 3.29915C10.611 3.63639 10.3369 3.91048 10 3.91048C9.66309 3.91048 9.389 3.63639 9.389 3.29915C9.389 2.96224 9.66309 2.68815 10 2.68815ZM9.37596 4.40741C9.56132 4.51231 9.77228 4.57715 10 4.57715C10.2276 4.57715 10.4383 4.51239 10.6236 4.40763L13.6208 7.0625H6.37878L9.37596 4.40741ZM17.3333 16.6458C17.3333 17.0133 17.0342 17.3125 16.6667 17.3125H3.33333C2.96582 17.3125 2.66667 17.0133 2.66667 16.6458V8.39583C2.66667 8.02832 2.96582 7.72917 3.33333 7.72917H16.6667C17.0342 7.72917 17.3333 8.02832 17.3333 8.39583V16.6458Z"
          fill="#5C5C5C"
        />
        <path
          d="M16.3333 8.39453H3.66665C3.4824 8.39453 3.33331 8.54362 3.33331 8.72786V16.3112C3.33331 16.4954 3.4824 16.6445 3.66665 16.6445H16.3333C16.5176 16.6445 16.6666 16.4954 16.6666 16.3112V8.72786C16.6666 8.54362 16.5176 8.39453 16.3333 8.39453ZM16 15.9779H3.99998V9.0612H16V15.9779Z"
          fill="#5C5C5C"
        />
      </svg>
    </div>
    <div class="preview">
      <svg
        class="preview-background"
        xmlns="http://www.w3.org/2000/svg"
        width="193"
        height="74"
        viewBox="0 0 193 74"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M182.717 9.50001C182.181 9.50001 181.686 9.21411 181.418 8.75001L176.799 0.75C176.222 -0.25 174.778 -0.25 174.201 0.75L169.582 8.75001C169.314 9.21411 168.819 9.50001 168.283 9.50001H5C2.23858 9.50001 0 11.7386 0 14.5V68.5C0 71.2614 2.23858 73.5 5 73.5H188C190.761 73.5 193 71.2614 193 68.5V14.5C193 11.7386 190.761 9.50001 188 9.50001H182.717Z"
          fill="#FAF9F3"
        />
      </svg>
      <div class="item">
        <div class="item-information">
          <img class="item-image bg-ms-white">
          <div class="item-details">
            <span class="caption-2 item-name">Abstract Textured</span>
            <span class="caption-3 item-price">From $ 99</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  setup() {},
});
</script>

<style scoped lang="scss">
.preview-holder {
  display: flex;
  width: auto;
  height: auto;
  justify-content: center;
  align-items: center;
  position: relative;
  .preview-icon {
    height: 34px;
    width: 34px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .preview {
    background-color: transparent;
    width: 193px;
    height: 89.5px;
    flex-shrink: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    right: 0;
    padding-top: 7px;
    .preview-background {
      z-index: 0;
      height: auto;
      width: auto;
      position: absolute;
      filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.10));
    }
    .item {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      height: 100%;
      width: 100%;
      user-select: none;
      .item-information {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        .item-image {
          height: 40px;
          width: 40px;
        }
        .item-details {
          color: $color-secondary;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          gap: 10px;
          .item-name {
            white-space: pre;
          }
          .item-price {
            white-space: pre;
          }
        }
      }
      .item-button {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        width: auto;
      }
    }
  }
  &:hover {
    .preview {
      visibility: visible;
    }
  }
}
</style>
